@import "variables";

#htmlcontent_top{
    margin: 30px 0;
    background: theme-color(white);
    padding-top: 30px;
}

div[class^=htmlcontent-item-]{
    height:300px;
    background-color:theme-color(black);
    background-position:top left;
    background-repeat:no-repeat;
    background-size:cover;
    color:theme-color(white);
    margin-bottom:30px;
    text-align:center;
    vertical-align:middle;
}

@media(max-width:420px){
    div[class^=htmlcontent-item-]{
        height: 250px;
    }
}

.title-htmlcontent{
    font-size: 18px;
    text-align: center;
    color: theme-color(secondary);
    font-family: filson-pro, sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
}
.title-htmlcontent span{
    border-bottom: theme-color(secondary) solid 3px;
    padding-bottom: 5px;
}

div[class^=htmlcontent-item-] a{
    display:inline-block;
    position:relative;
    top:0;
    left:0;
    z-index:1;
    width:100%;
    height:100%;
    padding:20px;
    background:rgba(0,0,0,0.2);
    color:theme-color(white);
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    line-height:250px;
    vertical-align:middle;
    -webkit-transition:all,ease,.3s;
    -moz-transition:all,ease,.3s;
    -ms-transition:all,ease,.3s;
    -o-transition:all,ease,.3s;
    transition:all,ease,.3s;
    font-family: filson-pro, sans-serif;
    font-weight: 700;
}
@media(max-width:420px){
    div[class^=htmlcontent-item-] a{
        line-height: 200px;
    }
}
div[class^=htmlcontent-item-] a:hover,div[class^=htmlcontent-item-] a:focus{
    text-decoration:none;
    background:rgba(0,0,0,0.5);
}
div[class^=htmlcontent-item-] a h3{
    display:inline-block;
    margin:0;
    padding:0;
    width:100%;
    font-weight:normal;
    font-size:24px;
    vertical-align:middle;
}